/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-lg-8>
    <v-flex xs12>
      <v-layout wrap justify-start py-4>
        <v-flex xs12 sm6 lg2 align-self-center>
          <h3>
            {{ $t("pages.departments.all_departments") }}
          </h3>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs3 align-self-center text-right>
          <AppNavButton :route="{ name: 'department.add' }" custom-class="pa-5">
            <template #content>
              <v-icon size="14px">mdi-plus</v-icon>
              {{ $t("pages.departments.add_new_department") }}
            </template>
          </AppNavButton>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start v-if="list.length">
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            empty-title="pages.departments.no_pools_to_show"
            @paginate="(val) => (pagination.page = val)"
            @delete="toggleDelete"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      @cancel="toggleDelete"
      @submit="deleteDepartment"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import Helpers from "@/libs/helper/components/helper-functions";

export default {
  name: "Departments",
  data: () => ({
    selectedItem: {},
    showDelete: false,
    headings: [
      { name: "", value: "index", style: "width:80px" },
      { name: "Id", value: "id", style: "width:80px" },
      { name: "form.name", value: "name" },
      { name: "form.phone", value: "phone" },
      { name: "form.address", value: "address" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["view", "edit", "delete"],
        view: "department",
        edit: "department.edit",
      },
    ],

    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      departments: "app/getDepartments",
    }),
    list() {
      return this.departments.map((itm, index) => {
        return {
          index: this.pagination.from + index,
          id: itm.id,
          name: itm.name,
          phone: itm.phone,
          address: itm.address,
        };
      });
    },
  },
  created() {
    this.pagination.page = parseInt(this.$route.query.page) || 1;
    this.getData();
  },
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "pages.departments.title",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  watch: {
    "pagination.page"() {
      this.updateQueryParams();
      this.getData();
    },
  },
  methods: {
    updateQueryParams() {
      this.$router.replace({
        query: {
          page: this.pagination.page,
        },
      });
    },
    getData() {
      const payload = {
        ...this.pagination,
      };
      this.$store.dispatch("app/getDepartments", payload).then((res) => {
        this.pagination = Helpers.setPagination(res);
      });
    },
    toggleDelete(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    deleteDepartment() {
      this.$store
        .dispatch("hospital/deleteDepartment", this.selectedItem.id)
        .then(() => {
          this.toggleDelete();
          this.getData();
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}
.v-text-field--outlined::v-deep input::placeholder,
.v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}
</style>
